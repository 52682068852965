html, 
body { 
    height: 100vh;
    padding: 0;
    margin: 0;
}
body {
    background: #F1F5F4;
    display: flex;
    align-items: center;
    justify-content: center;
}
@keyframes logo {
    from {
        transform: scale(0.6);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
img {
    opacity: 0;
    animation: logo 0.6s ease-out forwards;
    animation-delay: 0.3s;
}